var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderDetailMain"},[_c('iHeader',{attrs:{"backType":"blank","headStyle":['msd', 'dsm'].includes(_vm.$cookies.get('tenant'))
        ? {
            color: '#13161B',
          }
        : {
            background: 'var(--themeColor)',
            color: '#fff',
          },"backEvent":true,"back":true},on:{"back-event":function($event){return _vm.orderDetailback()}}},[(
        _vm.$route.query.isApproval &&
        (['GaoDe'].includes(_vm.onlineDetail.sourceCode) ||
          (_vm.onlineDetail.sourceCode == 'DiDi' &&
            _vm.onlineDetail.itsItemData.isUseH5))
      )?_c('div',{attrs:{"slot":"rightBtn"},on:{"click":function($event){return _vm.viewOrder()}},slot:"rightBtn"},[_vm._v(" 更多详情 ")]):_vm._e()]),_c('div',{staticClass:"map",attrs:{"id":"container"}}),(_vm.onlineDetail)?_c('platformInforPiece',{attrs:{"onlineDetail":_vm.onlineDetail,"submitOrder":_vm.submitOrder,"isloding":_vm.isloding}}):_vm._e(),_c('cancelResonPop',{ref:"cancelResonPop",attrs:{"canselect":_vm.canselect,"cancelReson":_vm.cancelReson}}),(_vm.isFirst)?_c('Skeleton',{attrs:{"skList":_vm.skList}}):_vm._e(),_c('MessageBox',{ref:"tipsMsg",attrs:{"type":"tips","messageTxt":_vm.messageTxt},on:{"on-ok":function($event){return _vm.MessageBtn()}}}),_c('Map',{ref:"mapPop",on:{"close":_vm.getGetDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
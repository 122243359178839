<template>
  <div class="orderDetailMain">
    <iHeader
      backType="blank"
      :headStyle="
        ['msd', 'dsm'].includes($cookies.get('tenant'))
          ? {
              color: '#13161B',
            }
          : {
              background: 'var(--themeColor)',
              color: '#fff',
            }
      "
      :backEvent="true"
      @back-event="orderDetailback()"
      :back="true"
    >
      <div
        v-if="
          $route.query.isApproval &&
          (['GaoDe'].includes(onlineDetail.sourceCode) ||
            (onlineDetail.sourceCode == 'DiDi' &&
              onlineDetail.itsItemData.isUseH5))
        "
        slot="rightBtn"
        @click="viewOrder()"
      >
        更多详情
      </div>
    </iHeader>
    <div id="container" class="map"></div>
    <platformInforPiece
      v-if="onlineDetail"
      :onlineDetail="onlineDetail"
      :submitOrder="submitOrder"
      :isloding="isloding"
    ></platformInforPiece>
    <cancelResonPop
      ref="cancelResonPop"
      :canselect="canselect"
      :cancelReson="cancelReson"
    ></cancelResonPop>
    <Skeleton v-if="isFirst" :skList="skList" />
    <MessageBox
      ref="tipsMsg"
      type="tips"
      :messageTxt="messageTxt"
      @on-ok="MessageBtn()"
    ></MessageBox>
    <Map ref="mapPop" @close="getGetDetail" />
  </div>
</template>
<script>
import choiseExpenseItem from "@/views/controllers/choiseExpenseItem.js";
import Map from "@/views/common/map/map";
import cancelResonPop from "@/views/common/platform/cancelResonPop";
import platformInforPiece from "@/views/common/platform/platformInforPiece";
import submitOrder from "@/views/controllers/submitOrder.js";
import mapSet from "@/views/controllers/mapSet.js";
import filterEmoji from "@/views/controllers/filterEmoji.js";
export default {
  mixins: [submitOrder, mapSet, filterEmoji, choiseExpenseItem],
  data() {
    return {
      iframeUrl: "",
      showDiDiH5: false,
      isloding: true,
      skList: [
        {
          width: "65%",
          row: 5,
        },
        {
          width: "20%",
          position: "right",
        },
        {
          width: "40%",
          inline: true,
        },
        {
          width: "50%",
        },
        {
          width: "30%",
          ml: ".1rem",
        },
        {
          width: "15%",
          inline: true,
        },
      ],
      isFirst: true,
      messageTxt: "",
      cancelReson: [],
      clickItem: {},
      maplineArra: [],
      orderDetail: {},
      onlineDetail: {},
      markerList: [],
      points: [],
      arr: [],
      che: [],
      iindex: 0,
      polyline: [],
      canselect: true,
      reason: "",
      infoWindow: {},
    };
  },
  components: {
    platformInforPiece,
    cancelResonPop,
    Map,
  },
  computed: {},
  async created() {
    this.setRouteListeners("orderDetailback");
    this.$root.$eventHub.$on("orderDetailback", (data) => {
      this.orderDetailback();
      this.$root.$eventHub.$off("orderDetailback"); //解绑当前监听，否则下轮监听还会持续叠加
    });
    if (this.SmartStorage.get("orderDetail")) {
      this.orderDetail = this.SmartStorage.get("orderDetail");
      await this.getGetDetail();
      // await this.MapLoaderM();
      this.markerList = [];
      this.arratGps = [];
      // console.log(this.map);
      this.iindex = 0;
      this.arr = [
        {
          lng: "121.44562",
          lat: "31.24663",
        },
        {
          lng: "121.44553",
          lat: "31.24668",
        },
        {
          lng: "121.44478",
          lat: "31.24699",
        },
        {
          lng: "121.44443",
          lat: "31.24689",
        },
        {
          lng: "121.44483",
          lat: "31.24466",
        },
        {
          lng: "121.44502",
          lat: "31.24431",
        },
        {
          lng: "121.45044",
          lat: "31.24524",
        },
        {
          lng: "121.45156",
          lat: "31.24495",
        },
      ];
      this.che = [
        {
          lng: "121.44562",
          lat: "31.24663",
        },
        {
          lng: "121.44553",
          lat: "31.24668",
        },
      ];

      this.installSingler();
    }
  },
  beforeDestroy() {
    this.orderDetail = null;
    this.markerList = null;
    this.$root.$eventHub.$off("orderDetailback");
    this.refreshHub.uninstall();
    let isAndroid =
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1; //android终端
    if (isAndroid && !this.SmartStorage.get("isH5")) {
      this.removeOnResumeEvent();
    }
  },
  methods: {
    h5GoClose() {
      this.showDiDiH5 = false;
    },
    viewOrder() {
      this.onlineDetail.SourceCode = this.onlineDetail.sourceCode;
      this.isGoh5(this.onlineDetail, "", true);
    },
    refrashData() {
      this.getGetDetail();
    },
    setTimeTest() {
      console.log(this.iindex);
      this.iindex += 1;

      if (this.arr[this.iindex] && this.arr[this.iindex].lng) {
        this.arratGps.push(
          new AMap.LngLat(this.arr[this.iindex].lng, this.arr[this.iindex].lat)
        );
      }
      this.dynamicLine();
      if (this.iindex == 5) {
        this.map.clearMap();
        this.getGetDetail();
      }
    },
    MapLoaderM() {
      let that = this;
      console.log("地图加载成功");
      that.map = new AMap.Map("container", {
        showTraffic: false, //实时路况信息
        resizeEnable: true,
        expandZoomRange: true,
        // zooms: [9, 17],
        // mapStyle: "amap://styles/whitesmoke",
      });
      if (that.onlineDetail.status != -1) {
        that.map.setFeatures(["road", "point"]);
        that.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
        });
        that.lineDriving = new AMap.Driving({
          map: that.map,
          hideMarkers: true,
          outlineColor: "balck",
          autoFitView: false,
        });
        // AMap.plugin("AMap.Driving", function () {
        //   that.lineDriving = new AMap.Driving({
        //     policy: AMap.DrivingPolicy.LEAST_TIME,
        //     panel: "panel"
        //   });
        // });
        let isAndroid =
          navigator.userAgent.indexOf("Android") > -1 ||
          navigator.userAgent.indexOf("Adr") > -1; //android终端
        if (isAndroid && !this.SmartStorage.get("isH5")) {
          that.addOnResumeEvent();
        }
        if (that.onlineDetail.sourceCode == "EHI") {
          that.gpsconvertFrom(that.maplineArra);
        } else {
          that.initmaps(that.maplineArra);
          that.maplineArra.forEach((element) => {
            that.arratGps.push(new AMap.LngLat(element.lng, element.lat));
          });
          // this.initmaps(that.che);
          // setInterval(this.setTimeTest, 5000);
        }
      } else {
        this.addyMarck2();
        this.map.panBy(20, -100);
      }
    },

    async submitOrder(item, btn) {
      this.clickItem = item;
      await this[btn.processor](item, btn);
    },
    orderDetailback() {
      this.removeRouteListeners("orderDetailback");
      localStorage && localStorage.setItem("_AMap_AMap.IndoorMap", "");
      this.$store.commit("setValue", {
        key: "homeWait",
        value: {
          date: new Date(),
          type: "back",
        },
      });
      this.$router.push({
        path: "/approvalList",
        query: {
          IsOnline: true,
        },
      });
    },
    gpsArraty(data) {
      let that = this;
      if (data && data.serviceStatus == "serviceStarted") {
        // serviceStarted    inService
        console.log(11);
        that.arratGps = [];
        that.map.remove(that.polyline);
        that.map.remove(that.marker);
        that.getGetDetail();
      } else if (data && data.serviceStatus == "serviceFinished") {
        that.map.clearMap();
        that.getGetDetail();
      } else if (data && data.driverGps && data.driverGps.lng) {
        console.log(22);
        that.arratGps.push(
          new AMap.LngLat(data.driverGps.lng, data.driverGps.lat)
        );
        console.log(that.arratGps);
        that.dynamicLine();
      }
      console.log(that.arratGps);
    },
    installSingler() {
      let that = this;
      that.refreshHub = new this.iSignalr({
        hubName: "SmartHub",
        queryParams: "group=" + that.SmartStorage.get("orderDetail").ItemId,
        listeners: [
          {
            methodName: "Refresh",
            method: function (datas) {
              console.log(datas);
              let data;
              if (that.onlineDetail.sourceCode == "EHI") {
                AMap.convertFrom(
                  AMap.LngLat(datas.driverGps.lng, datas.driverGps.lat),
                  "baidu",
                  function (status, result) {
                    if (result.info === "ok") {
                      datas.driverGps = result.locations[0];
                      that.gpsArraty(datas);
                    }
                  }
                );
              } else {
                that.gpsArraty(datas);
              }
            },
          },
        ],
        // host: "http://signalr.smartmice.cn",
        host: process.env.VUE_APP_SIGNALER,
      });
      that.refreshHub.install();
    },
    // 获取订单详情
    async getGetDetail() {
      this.isFirst = true;
      let params = {
        proposalId: this.orderDetail.ProposalId,
        itemId: this.orderDetail.ItemId || this.orderDetail.itemId,
      };
      this.services.GetOnlineDetail(params).then((res) => {
        if (res.success) {
          this.map && this.map.clearMap();
          this.onlineDetail = res.content;
          this.onlineDetail.itsItemData.passenger.name =
            this.StringExchangeEmoji(
              this.onlineDetail.itsItemData.passenger.name
            );
          this.onlineDetail.extOrderStatus == 100 &&
            this.onlineDetail.driverGps &&
            this.onlineDetail.driverGps.splice(
              this.onlineDetail.driverGps.findIndex((item) => item.lng == 0),
              1
            );
          this.maplineArra =
            this.onlineDetail.status == -1
              ? []
              : this.onlineDetail.driverGps &&
                this.onlineDetail.driverGps.length > 0
              ? this.onlineDetail.driverGps
              : this.onlineDetail.status != -1 && this.onlineDetail.status != 5
              ? this.onlineDetail.carBeforeArrivedGps
              : [];
          this.isFirst = false;
          // if (res.content.sourceCode == "EHI") {
          //   this.gpsconvertFrom(this.maplineArra);
          // } else {
          //   this.initmaps(this.maplineArra);
          //   this.maplineArra.forEach((element) => {
          //     this.arratGps.push(new AMap.LngLat(element.lng, element.lat));
          //   });
          // }
          // this.initmaps(this.che);
          this.MapLoaderM();
        }
      });
    },
  },
};
</script>
<style lang="sass" scoped>
@import '@/assets/platform/orderDetail.scss'
</style>